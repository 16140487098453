<div class="header-general flex justify-between">
  <div class="h3 m-0 p-2  ml-4">{{ "CONSUMPTION_UPLOAD_COMPONENT.TITLE" | translate }}</div>
  <a (click)="cancel()" class="mr-4">
    <mat-icon>close</mat-icon>
  </a>
</div>
<div class="content">
  <div class="grid grid-cols-3  ">

    <mat-form-field class="input-field-wrapper">
      <mat-label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.YEAR" | translate }}</mat-label>
      <input matInput [(ngModel)]="consumption['year']" id="year" type="number" required>
    </mat-form-field>

    <mat-form-field class="input-field-wrapper">
      <mat-label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.SOURCE" | translate }}</mat-label>
      <mat-select [(ngModel)]="consumption['source']" id="source"
        (selectionChange)="onSourceChange(consumption['source']['english'])" required>
        <mat-option *ngFor="let source of sources" [value]="source">{{source['translation']}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-field-wrapper">
      <mat-label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.AMOUNT" | translate }}</mat-label>
      <input matInput [(ngModel)]="consumption['amount']" id="amount" type="number" required>
    </mat-form-field>

    <mat-form-field class="input-field-wrapper">
      <mat-label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.AMOUNT_UNIT" | translate }}</mat-label>
      <mat-select [(ngModel)]="consumption['amountUnit']" id="amountUnit" required>
        <mat-option value="{{au.unit}}" *ngFor="let au of allowedUnit">{{au.title}}</mat-option>
        <!-- <mat-option value="btu" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">BTU</mat-option>
      <mat-option value="cu_ft" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">Cubic feet</mat-option>
      <mat-option value="cu_yard" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">Cubic yards</mat-option>
      <mat-option value="m3" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">Cubic meters</mat-option>
      <mat-option value="cf" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">Centrum cubic feet</mat-option>
      <mat-option value="Cubic Meters" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">Cubic Meters</mat-option>
     

      <mat-option value="gal" *ngIf="consumption['topic']=='Energy' || consumption['topic']=='Energy'">Gallons</mat-option>
      <mat-option value="g" *ngIf="consumption['topic']=='Energy'">Grams</mat-option>
      <mat-option value="j" *ngIf="consumption['topic']=='Energy'">Joule</mat-option>
      <mat-option value="KWH" *ngIf="consumption['topic']=='Energy'">Kilowatt hours</mat-option>
      <mat-option value="Kg" *ngIf="consumption['topic']=='Energy'">Kg</mat-option>
      <mat-option value="L" *ngIf="consumption['topic']=='Energy'">Liters</mat-option>
      <mat-option value="MJ" *ngIf="consumption['topic']=='Energy'">Mega joule</mat-option>
      <mat-option value="oz" *ngIf="consumption['topic']=='Energy'">Ounces</mat-option>
      <mat-option value="lb" *ngIf="consumption['topic']=='Energy'">Pounds</mat-option>
      <mat-option value="Ton" *ngIf="consumption['topic']=='Energy'">Tons</mat-option> -->
      </mat-select>
      <mat-error *ngIf="!consumption['amountUnit']">
        Unit (volume) can only be selected after source has been selected.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input-field-wrapper">
      <mat-label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.TIME_UNIT" | translate }}</mat-label>
      <mat-select [(ngModel)]="consumption['timeUnit']" id="timeUnit" required>
        <mat-option value="Year">Per Year</mat-option>
        <mat-option value="Month">Per Month</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-field-wrapper">
      <mat-label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.COMMENT" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="consumption['comment']" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        id="comment"></textarea>
    </mat-form-field>
    <div class="input-field-wrapper file-input-wrapper">
      <label>{{ "CONSUMPTION_UPLOAD_COMPONENT.LABELS.FILE_SELECT" | translate }}</label>
      <input type="file" (change)="addFile($event.target.files)" id="file" required>
      <button *ngIf="hasFile()" (click)="clearContent()"><mat-icon class="clear-button-icon">cancel</mat-icon></button>
    </div>



  </div>
  <br />
  <div class="buttons-div">
    <button class="btn btn-secondary" (click)="cancel()">{{ "CONSUMPTION_UPLOAD_COMPONENT.BUTTONS.CANCEL" | translate
      }}</button>
    <button class="ml-4 btn btn-primary" (click)="upload()">{{ "CONSUMPTION_UPLOAD_COMPONENT.BUTTONS.UPLOAD" | translate
      }}</button>
  </div>

</div>