import { A11yModule } from '@angular/cdk/a11y';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeZh from '@angular/common/locales/zh-Hans';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule, TooltipModule } from '@swimlane/ngx-charts';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxLoadingModule } from 'ngx-loading';
import { MarkdownModule } from 'ngx-markdown';
import { AccordionModule } from 'primeng-lts/accordion';
//import { SharedModule } from 'primeng-lts/api';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { FileUploadModule } from 'primeng-lts/fileupload';
import { CreateUserDialogComponent } from './admin/user-onboarding/create-user-dialog/create-user-dialog.component';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { BrandLoginGuard } from './chem-iq/route-guard/brand.login-gurad';
import { MtsLoginGuard } from './chem-iq/route-guard/mts.login-guard';
import { SupplierLoginGuard } from './chem-iq/route-guard/supplier.login-guard';
import { ComponentDeactivateCheckGuard } from './common/gaurds/component-deactivate.guard';
import { ToastAlertModule } from './common/toast-alert/toast-alert.module';
import { ComplianceCheckerService } from './compliance-checker/compliance-checker.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChemicalDocumentListComponent } from './documentcenter/chemical-document-list/chemical-document-list.component';
import { ConsumptionDataUploadComponent } from './documentcenter/consumption-data-upload/consumption-data-upload.component';
import { DocumentUploadComponent } from './documentcenter/document-upload/document-upload.component';
import { DocumentcenterComponent } from './documentcenter/documentcenter.component';
import { WasteWaterComponent } from './documentcenter/waste-water/waste-water.component';
import { FactoryReportingComponent } from './factory-reporting/factory-reporting.component';
import { FormulaDetailsComponent } from './formula-details/formula-details.component';
import { FormulaGlobalDataService } from './formula-global-data/formula-global-data.service';
import { GatewayAuthenticationService } from './gateway/authentication/gateway-authentication.service';
import { ExcludedChemicalsComponent } from './gateway/excluded-chemicals/excluded-chemicals.component';
import { SearchIncheckInventoryComponent } from './gateway/search-incheck-inventory/search-incheck-inventory.component';
import { SubscriptionFormComponent } from './gateway/subscription-form/subscription-form.component';
import { DataSharedServiceService } from './globals-interface/datashared.service';
import { AuthenticationService } from './globals-services/authentication.service';
import { GlobalsService } from './globals-services/globals.service';
import { PurchaseOrderService } from './globals-services/purchase-order.service';
import { SessionStateService } from './globals-services/session-state-service/session-state.service';
import { BulkInventoriesUploadComponent } from './inventories-overview/bulk-inventories-upload/inventories-bulk-update.component';
import { InventoriesOverviewComponent } from './inventories-overview/inventories-overview.component';
import { SupplierInventoriesOverviewComponent } from './inventories-overview/supplier-inventories-overview/supplier-inventories-overview.component';
import { InventoriesComponent } from './inventories/inventories.component';
import { AddInventoryScanComponent } from './inventory/add-inventory-scan/add-inventory-scan.component';
import { DeletionRequestComponent } from './inventory/deletion-request/deletion-request.component';
import { EditDataDialogComponent } from './inventory/edit-data-dialog/edit-data-dialog.component';
import { InputInventoryComponent } from './inventory/input-inventory/input-inventory.component';
import { InventoryComponent } from './inventory/inventory.component';
import { LanguageService } from './language/language.service';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { KeycloakGuard } from './modules/auth/login/keycloak.guard';
import { LoginFormComponent } from './modules/auth/login/login-form.component';
import { PasswordResetComponent } from './modules/auth/login/password-reset/password-reset.component';
import { Nl2brPipe } from './nl2br.pipe';
import { ProfileSubModule } from './profile/profile-main/profile-sub-module';
import { MakeStickyModule } from './shared/custom-directive/make-sticky/make-sticky.module';
import { SharedModule } from './shared/shared.module';
import { SupplierInventoriesComponent } from './supplier-inventories/supplier-inventories.component';
import { SupplierReportingComponent } from './supplier-reporting/supplier-reporting.component';
import { UserGuideComponent } from './user-guide/user-guide/user-guide.component';
import { UserSignupComponent } from './user-signup/user-signup.component';
import { WetProcessingUnitComponent } from './wet-processing-unit/wet-processing-unit.component';
import { WpuListComponent } from './wet-processing-unit/wpu-list.component';
import { BrandAnalyticsComponent, CommentDialog } from './brand-analytics/brand-analytics.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { BhiveDashboardEnvironmentService, BhiveDashboardModule } from 'bhive-dashboard'
import { CommonConfirmDailogComponent } from './common-confirm-dailog/common-confirm-dailog.component';
import { ToxServicesResourcesComponent } from './substitution/tox-services-resources/tox-services-resources.component';
import { ScriveraComponent } from './substitution/scrivera/scrivera.component';
import { StepDashboardComponent } from './step-dashboard/step-dashboard.component';
import { FormulaPieChartDialogComponent } from './visualizations/formula-pie-chart-dialog/formula-pie-chart-dialog.component';
import { MonthlyReportComponent } from './brand-analytics/monthly-report/monthly-report.component';
import { CAMonthlyReportComponent } from './brand-analytics/c-a-monthly-report/c-a-monthly-report.component';
import { FormulaPieChartModule } from 'app/visualizations/formula-pie-chart.module';
import { NewComplianceCheckerService } from './compliance-checker/new-compliance.service';
import { SimpleDocumentUploadComponent } from './documentcenter/document-upload/simple-document-upload/simple-document-upload.component';
import { CustomDashboardComponent } from './custom-dashboard/custom-dashboard.component';
import { environment } from 'environments/environment';
import { BrandSideNewLaunchDialogComponent } from './brand-side-new-launch-dialog/brand-side-new-launch-dialog.component';
// import { LayoutModule } from '@angular/cdk/layout';

registerLocaleData(localeZh);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        './assets/i18n/',
        '.json?v=' + Date.now()
    );
}

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
               // url: 'https://auth.thebhive.net/auth',
                url:environment.keycloakURL,
                realm: 'bhive',
                clientId: 'bhive-angular',
            },
            initOptions: {
                //  onLoad: 'check-sso',
                checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['my-aip.com'],
        });
}
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [
        AppComponent,
        LoginFormComponent,
        //  AlertComponent,
        DashboardComponent,
        InventoriesOverviewComponent,
        SupplierInventoriesOverviewComponent,
        InventoriesComponent,
        InventoryComponent,
        SupplierInventoriesComponent,
        EditDataDialogComponent,
        DocumentcenterComponent,
        FormulaDetailsComponent,
        AddInventoryScanComponent,
        PasswordResetComponent,
        InputInventoryComponent,
        DocumentUploadComponent,
        ConsumptionDataUploadComponent,
        ChemicalDocumentListComponent,
        WasteWaterComponent,
        SearchIncheckInventoryComponent,
        SubscriptionFormComponent,
        ExcludedChemicalsComponent,
        DeletionRequestComponent,
        BulkInventoriesUploadComponent,
        WpuListComponent,
        WetProcessingUnitComponent,
        Nl2brPipe,
        MarketplaceComponent,
        SupplierReportingComponent,
        FactoryReportingComponent,
        UserSignupComponent,
        CreateUserDialogComponent,
        //  FormulaPieChartsComponent,
        UserGuideComponent,
        BrandAnalyticsComponent,
        CommentDialog,
        CommonConfirmDailogComponent,
        ToxServicesResourcesComponent,
        ScriveraComponent,
        StepDashboardComponent,
        FormulaPieChartDialogComponent,
        MonthlyReportComponent,
        CAMonthlyReportComponent,
        SimpleDocumentUploadComponent,
        CustomDashboardComponent,
        BrandSideNewLaunchDialogComponent,
    ],
    entryComponents: [
        EditDataDialogComponent,
        FormulaDetailsComponent,
        DocumentUploadComponent,
        ConsumptionDataUploadComponent,
        AddInventoryScanComponent,
        ChemicalDocumentListComponent,
        CreateUserDialogComponent,
        WasteWaterComponent,
        SearchIncheckInventoryComponent,
        CreateUserDialogComponent,
        ExcludedChemicalsComponent,

        DeletionRequestComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,
        MatDatepickerModule,
        MatNativeDateModule,
        KeycloakAngularModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        AccordionModule,
        NgxLoadingModule.forRoot({ fullScreenBackdrop: true }),
        MatTableModule,
        MatIconModule,
        TooltipModule,
        BrowserModule,
        NgxChartsModule,
        MatNativeDateModule,
        MatInputModule,
        MatPaginatorModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatTabsModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatIconModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTabsModule,
        MatGridListModule,
        CheckboxModule,
        MatTooltipModule,
        FileUploadModule,
        PortalModule,
        OverlayModule,
        MatAutocompleteModule,
        SharedModule,
        AccordionModule,
        FormsModule,
        TranslateModule,
        ToastAlertModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        PdfViewerModule,
        MakeStickyModule,
        ProfileSubModule,
        FormulaPieChartModule,
        BhiveDashboardModule
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthenticationService,
        GatewayAuthenticationService,
        GlobalsService,
        //    AlertService,
        LanguageService,
        OverlayContainer,
        SessionStateService,
        ComplianceCheckerService,
        KeycloakGuard,
        PurchaseOrderService,
        DataSharedServiceService,
        DatePipe,
        TranslateService,
        MatDatepickerModule,
        SupplierLoginGuard,
        FormulaGlobalDataService,
        BrandLoginGuard,
        MtsLoginGuard,
        ComponentDeactivateCheckGuard,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true, direction: 'ltr' },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: BhiveDashboardEnvironmentService,
            useFactory: () => {
              const config = new BhiveDashboardEnvironmentService();
              config.setApiUrl(environment.baseHref);
              config.setCubeApiUrl(environment.cubeAPI);
              return config;
            }
          },
        NewComplianceCheckerService
    ],
    exports: [TranslateModule]
})
export class AppModule { }
