export const environment = {
    production: true,
    server: 'EU',

    baseHref: 'https://gw.thebhive.net',
    otherServer: 'https://gw.thebhive.cn', // this should be china or europe to transfer request between servers.

    homeUrl: 'https://login.thebhive.net',
    otherHomeUrl: 'https://login.thebhive.cn',

   // cubeAPI: "https://analytics.thebhive.net/cubejs-api/v1",
    cubeAPI: "https://analytics.prod.europe.thebhive.net/cubejs-api/v1",

    gatewayUrl: 'https://www.my-aip.com/Gateway/rest',
    incheckServiceUrl: 'https://gs.thebhive.net/gateway-services/api/gateway',
    faqUrl: "https://www.thebhive.net/membersfaq",
    metaBaseURL: 'https://get.thebhive.net',
    serverLocation: "Europe",
    gatewayAuthenticationKey: "B3EB14D9068472DCE0530100007FFB5A",
    recaptchaKey: '6Le6OrMaAAAAAJ1BkUHRczoHdbJ4ID6_AoDcGLDs',
    poServiceUrl :  'https://po.thebhive.net/po-services/api',
    sdsAPI: 'https://po.thebhive.net/po-services',
    authentication: {
      enabled: true,
      url: 'http://bluhive.slashcube.de:8080/api/login'
    },
    keycloakURL:'https://auth.thebhive.cn/auth'
  };
